import React, { useEffect } from "react";
import App from "./App";
import { Redirect, BrowserRouter, Switch, Route } from "react-router-dom";
import Deprecated from "./parts/Deprecated";

/**
 * Main Router for CLS modules
 * @author Kyaw Zin Soe
 * @datetime 1-Apr-2022
 */

/**
 * AppRouter component
 *
 * @param {object} props
 */
function AppRouter(props) {

  window.isMobileCLS = function () {
    return navigator && navigator.userAgent.includes("CLS");
  }

  /**
   * return rendered view 
   *
   */
  return (
    <>
      {window.isMobileCLS() ?
        <Deprecated
          message='Mobile app feature of CLS Phase 1 is Deprecated since Version 1.9.7! please open to following URL in web browser:'
        >
          <em>{window.location.href}</em>
        </Deprecated> :
        <BrowserRouter  >
          <Switch>
            <Route key={"route-1"} exact path="/">
              <Redirect to="/stocks/map" />
            </Route>
            <Route key={"route-global"} path="/*" component={App} exact></Route>
          </Switch>
        </BrowserRouter>}
    </>
  );
}

export default AppRouter;
