import { Messages } from "./messages";

/**
 * General component for loading alert messages.
 * @author Kyaw Zin Soe
 * @datetime 2-Apr-2022
 */

/**
 * MessageManager component
 *
 * @param {object} props
 */
export const MessageManger = {
  title: function (key, obj) {
    if (!Messages[key]) return "";
    let msgRaw = Messages[key].title;
    if (obj) {
      const paramsPattern = /[^{}]+(?=})/g;
      let extractParams = msgRaw.match(paramsPattern);
      for (let i in extractParams) {
        msgRaw = msgRaw.replaceAll(
          "{" + extractParams[i] + "}",
          obj[extractParams[i]] ? obj[extractParams[i]] : ""
        );
      }
    }
    return msgRaw;
  },
  message: function (key, obj) {
    if (!Messages[key]) return "";
    let msgRaw = Messages[key].message;
    if (obj) {
      const paramsPattern = /[^{}]+(?=})/g;
      let extractParams = msgRaw.match(paramsPattern);
      for (let i in extractParams) {
        msgRaw = msgRaw.replaceAll(
          "{" + extractParams[i] + "}",
          obj[extractParams[i]] ? obj[extractParams[i]] : ""
        );
      }
    }
    return msgRaw;
  },
};
