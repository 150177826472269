import React, { useState, useEffect } from "react";
import { MessageManger } from "./MessageManager";
import QrScanner from "react-qr-reader";
import { Modal,TextInput,Button,FormGroup } from "@wfp/ui";
import ApiClient from "../lib/ApiClient";
import { Entity } from "thematrix-entities";

/**
 * Parts/QrReader module to handle Package QR Scanning.
 * @author Kyaw Zin Soe
 * @datetime 6-Apr-2023
 */

/**
 * QrReader component
 *
 * @param {object} props
 */
function QrReader (props) {
    const [qrManualMode,setQrManualMode] = useState(false);
    const [qrPackageNumber,setQrPackageNumber] = useState(null);
    const [selectedCamera,setSelectedCamera] = useState('environment');

    useEffect(()=>{
       
    },[props.eUser]);

    return (
        <Modal
         className={"wide-box mid-dialog wide-box-f "+(qrManualMode?'':'tran-box')}
         onRequestClose={() => {
           props.setStartScan(false);
         }}
         hideClose={false}
         open={props.startScan}
         passiveModal
       >
        <div className="row">
        <div className="col-xs-4 col-md-4 col-lg-4">
            <button className=' f-w' onClick={()=>{
              setQrManualMode(!qrManualMode);
            }}>{!qrManualMode?<>Enter Pkg#</>:<>Scan QR</>}</button>
          </div>
                {!qrManualMode?<div className="col-xs-5 col-md-5 col-lg-5">
          <select className='f-w' onChange={(e) => setSelectedCamera(e.target.value)}>
            <option value={"environment"}>Back Camera</option>
            <option value={"user"}>Front Camera</option>
          </select>
          </div>:<></>}
          
          <div className="col-xs-3 col-md-3 col-lg-3">
            <button className=' f-w' onClick={()=>{
              props.setStartScan(false);
            }}>Cancel</button>
          </div>
          </div>
          {qrManualMode?
          <div className='r-select-box'>
            <FormGroup>
                <TextInput
                  id="inp-pkg"
                  labelText="Package No."
                  placeHolder={'PKG00000000'}
                  value={qrPackageNumber}
                  onChange={(e)=>{
                    setQrPackageNumber(e.target.value.trim());
                  }}
                />
              </FormGroup>
              <FormGroup>
              <Button
                onClick={async () => {
                  props.setLoading(true);
                  const resP = await ApiClient.get("/api/v1/emergencies/package/" + qrPackageNumber,props.eUser.accessToken);
                  if(!resP.data || resP.data.length<1){
                    props.setLoading(false);
                    alert(MessageManger.message('emergency_invalid_pkg'));
                    setQrPackageNumber("");
                    return;
                  }
                  let _ePkg = new Entity(resP.data[0], {});
                  
                  setTimeout(function(){
                    window.location.href='/emergencies/info/'+_ePkg.pl_number+'/'+qrPackageNumber;
                  },30);
                  
                }}
              >
                Submit
              </Button>
              </FormGroup>
          </div>
          :<QrScanner
            facingMode={selectedCamera}
            delay={1000}
            onError={(error)=>{
              console.log(error);
            }}
            onScan={(result)=>{
              if(result){
                let url = result.replace("cdemacls:", window.location.protocol);
            console.log(url);
            if (url.includes(window.location.host)) {
              props.setLoading(true);
              setTimeout(function(){
                window.location.href = url;
              },50);
              
            } else {
                props.setLoading(false);
              if (!result.text || result.text == "")
                alert(
                  MessageManger.title("mobile_qr_invalid") +
                    ", " +
                    MessageManger.message("mobile_qr_invalid")
                );
            }
              }
            }}
            
            style={{ width: "100%" }}
          />}
          </Modal>
    );
}

export default QrReader;