import React, { useEffect, useState } from "react";
import { Modal } from "@wfp/ui";
import ReactModal from "react-modal-resizable-draggable";

/**
 * Parts/QuickHelp module to show help in dialog iframe
 * @author Kyaw Zin Soe
 * @datetime 6-Apr-2023
 */

/**
 * QuickHelp component
 *
 * @param {object} props
 */
function QuickHelp(props) {

    const [quickHelpUrl, setQuickHelpUrl] = useState(false);


    /**
     * function to show quick help dialog
     * 
     * Quick Help content is the same as /help module, but in dialog mode.
     * 
     */
    const showQuickHelp = () => {

        let route = props.routes[0];
        let sub = props.routes[1];
        let _helpUrl = props.settings.documentation.base;
        let tag = props.settings.documentation[route][sub]
            ? props.settings.documentation[route][sub].tag
            : props.settings.documentation[route].default
                ? props.settings.documentation[route].default.tag
                : "";
        _helpUrl += props.settings.documentation[route]
            ? "?id=" + props.settings.documentation[route].base + (tag ? "#" + tag : "")
            : "";
        console.log('help', _helpUrl);
        setQuickHelpUrl(_helpUrl);
        props.setQuickHelpOpen(true);
    };

    useEffect(() => {
        if (props.quickHelpOpen) {
            showQuickHelp();
        }
    }, []);

    return (
        <>
            {window.screen.width < 600 ? (
                <Modal
                    onRequestClose={() => {
                        props.setQuickHelpOpen(false);
                    }}
                    passiveModal={true}
                    className={"s-box mid-dialog"}
                    hideClose={false}
                    open={props.quickHelpOpen}
                    modalHeading={"Help"}
                >
                    <iframe
                        title="quick-help"
                        style={{ height: window.screen.height * 0.7 + "px" }}
                        className="min-h-400p"
                        src={quickHelpUrl}
                    ></iframe>
                </Modal>
            ) : (
                <ReactModal
                    initWidth={800}
                    initHeight={600}
                    top={60}
                    onRequestClose={() => {
                        props.setQuickHelpOpen(false);
                    }}
                    isOpen={props.quickHelpOpen}
                >
                    <iframe title='help' src={quickHelpUrl}></iframe>
                    <button
                        onClick={() => {
                            props.setQuickHelpOpen(false);
                        }}
                        className="wfp--modal-close"
                        type="button"
                    >
                        <svg
                            className="wfp--modal-close__icon"
                            fill-rule="evenodd"
                            height="10"
                            role="img"
                            viewBox="0 0 10 10"
                            width="10"
                            aria-label="close the modal"
                            alt="close the modal"
                        >
                            <title>close the modal</title>
                            <path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path>
                        </svg>
                    </button>
                </ReactModal>
            )}
        </>);
}

export default QuickHelp;