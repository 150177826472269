import React, { useState } from "react";
import { Link, User, Button } from "@wfp/ui";
import { FaQrcode } from "react-icons/fa";
import Helper from "../lib/Helper";

/**
 * Main Navigation
 * @author Kyaw Zin Soe
 * @datetime 2-Apr-2022
 */

/**
 * Nav component
 *
 * Nav component is for top Nav bar
 *
 * @param {object} props
 */
const Nav = function (props) {
  // const menuRef=useRef();

  const logo = () => {
    /**
     * return rendered view
     *
     */
    return (
      <span>
        {(() => {
          if (props.enable) {
            return (
              <>
                <img
                  className="va-m"
                  src="/toggle.svg"
                  alt="toggle"
                  onClick={props.navToggle}
                />
                &nbsp;&nbsp;&nbsp;
              </>
            );
          } else {
            return <></>;
          }
        })()}

        <span className="pr-vm si-mob t-mob" href="/">
          <img className="si-mob" alt="calm" src="/logo-sm.png" /> CLS
        </span>
        <span className="pr-vm h-mob">
          <img src="/logo-cdema.png" alt="CDEMA" />{" "}
          <span>CLS – CDEMA Logistics System </span>{" "}
          <img className="flag-img" src="/top-flag.png?v=0.0.1" alt="CDEMA" />
        </span>
      </span>
    );
  };

  const CustomMainMenu = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    /**
     * return rendered view
     *
     */
    return (
      <div className={"wfp--main-navigation "+(window.location.hostname!='cls-cdema.org'?'demo-color':'')}>
        <div
          className={
            "wfp--wrapper wfp--wrapper--width-mobile-full wfp--main-navigation__wrapper " +
            (props.className ? props.className : "")
          }
        >
          <div className="wfp--main-navigation__logo-wrapper">
            <>{/*<button
              tabIndex="0"
              className="wfp--main-navigation__button wfp--btn wfp--btn--primary"
              type="button"
              onClick={() => {
                setMenuOpen(!menuOpen);
                props.setNavState("open");
              }}
            >
              Menu
            </button>*/}
            {props.route !== "login" ? (
              <>
              {!window.isPhone() || (window.isPhone() && props.eUser.actionLink !== "form")?
                <Link
                className='w-link wfp--main-navigation__button'
                  href={
                    props.eUser._isGuest() ? "/login" : "/action/auth/logout"
                  }
                >
                  {props.eUser._isGuest() ? "Login" : "Logout"}
                </Link>:<>
                {/*props.eUser.actionLink === "form"?<>
                <Button
                  className="act-button"
                  onClick={(a) => {
                    props.eUser._action(a);
                  }}
                >
                  Submitx
                </Button>
                </>:<></>*/}
                </>}
                </>
              ) : null}
              </>
            <div className="wfp--main-navigation__logo">{props.logo}</div>
          </div>
          <ul
            className={
              "wfp--main-navigation__list " +
              (menuOpen ? "wfp--main-navigation__list--open" : "")
            }
          >
            {props.items.map((item, index) => {
              if (!item.show) return <span key={"nl" + index}></span>;
              return (
                <li
                  key={"nl" + index}
                  className={
                    "wfp--main-navigation__item " +
                    (item.className ? item.className : "")
                  }
                >
                  <div className="wfp--main-navigation__trigger">
                    {item.view}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };
  return (
    /**
     * return rendered view
     *
     */
    <CustomMainMenu
      setNavState={props.setNavState}
      eUser={props.eUser}
      logo={logo()}
      className={
        window.location.port === "3000" ||
          window.location.href.includes("training")
          ? "test-mode"
          : ""
      }
      items={[
        {
          view: (
            <>
              {props.eUser.actionLink === "form" ? (
                <Button
                  className="act-button"
                  onClick={(a) => {
                    props.eUser._action(a);
                  }}
                >
                  Submit
                </Button>
              ) : (
                <>
                  {(!props.eUser._isGuest() ) ? (
                    <Link onClick={()=>{
                      props.handleSrfAction();
                    }}>
                      Create Service Request
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          ),
          show: true,
          className: "phone-hide",
        },
        {
          view: (
            <>
              {" "}
              {props.eUser && !props.eUser._isGuest() ? (
                <User
                  ellipsis
                  title={props.eUser.name ? props.eUser.name : "guest"}
                  name={props.eUser.name ? props.eUser.name : "guest"}
                />
              ) : (
                <></>
              )}
            </>
          ),
          show:! window.isPhone(),
        },
        {
          view: (
            <>
             {props.eUser.meta && props.eUser.meta.simulation?<span className={props.simWaitMode?'red blink-item':'orange'}>
              Reset in next: {props.simResetTime}{'  '}
             </span>:<></>}
              {props.route !== "login" ? (
                <Link
                  href={
                    props.eUser._isGuest() ? "/login" : "/action/auth/logout"
                  }
                >
                  {props.eUser._isGuest() ? "Login" : "Logout"}
                </Link>
              ) : null}
            </>
          ),
          show: true,
        },
      ]}
    ></CustomMainMenu>
  );
};

export { Nav };
