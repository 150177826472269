import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './index.css';
import './wfpFix.css';
import AppRouter from './AppRouter';
import * as serviceWorker from './serviceWorker';

/**
 * Entry Script for CLS
 * @author Kyaw Zin Soe
 * @datetime 2-Apr-2022
 */

// reserved experimental service worker
//serviceWorker.unregister();
// let _excludes=[
//   'action', // ignore actions
//   'api', // ignore api
//   'app', // ignore app
// ];
// let pN=window.location.pathname.split('/');
// console.log(pN.length>1?pN[1]:'no-route',_excludes.includes(pN[1]));
// if (pN.length>1 && _excludes.includes(pN[1])) {
//   //alert('here');
//   console.log('unregistering..');
//   serviceWorker.unregister();
//   window.location.reload(true);
// }else{

// ReactDOM.render(
//     <AppRouter />,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

ReactDOM.render(
  <AppRouter />,
document.getElementById('root')
);
// if(window.location.pathname.match(/action|api|app\/*/ )==1){
// serviceWorker.unregister();
// }else{
//   serviceWorker.register();
// }
serviceWorker.unregister();
//  serviceWorker.register({
//   excludeRoutes:/action|api|app\/*/ 
// });

