/**
 * Lib/ApiClient for GET/POST Rest API client
 *
 * @author Kyaw Zin Soe
 * @datetime 29-Mar-2022
 */

/**
 * Apiclient object
 *
 */
const ApiClient = {
  opt: function (data,token) {
    let headers={
      "Accept": "application/json",
      "Content-Type": "application/json", // request content type
    };
    if(token){
      headers['Authorization']='Bearer '+token
    }
    if (undefined === data || null === data) {
      return {
        method: "GET", //  GET, POST, PUT, DELETE
        mode: "cors", // Type of mode of the request
        cache: "no-cache", // options like default, no-cache, reload, force-cache
        credentials: "same-origin", // options like include, *same-origin, omit
        headers: headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
      };
    }
    return {
      method: "POST", //  GET, POST, PUT, DELETE
      mode: "cors", // Type of mode of the request
      cache: "no-cache", // options like default, no-cache, reload, force-cache
      credentials: "same-origin", // options like include, *same-origin, omit
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data), // Attach body with the request
    };
  },
  get: async function (url, token,callback) {
    let headers={
      "Accept": "application/json",
      "Content-Type": "application/json", // request content type
    };
    if(token){
      headers['Authorization']='Bearer '+token
    }
    const response = await fetch(url, {
      method: "GET", // *Type of request GET, POST, PUT, DELETE
      mode: "cors", // Type of mode of the request
      cache: "no-cache", // options like default, no-cache, reload, force-cache
      credentials: "same-origin", // options like include, *same-origin, omit
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      // body: JSON.stringify(data) // Attach body with the request
    });

    let _data = await response.json();
    if (callback) {
      callback(_data);
    } else {
      return _data;
    }
  },
  getAll1: async function (urls,token,callback){
    let headers={
      "Accept": "application/json",
      "Content-Type": "application/json", // request content type
    };
    if(token){
      headers['Authorization']='Bearer '+token
    }
    let getFetches=()=>{
      let arr=[];
      for(let i in urls){
        arr.push(fetch(urls[i], ApiClient.opt(undefined,token)),);
      }
      return arr;
    }
    let resAll= await Promise.all(getFetches()).then(async resAll=>{
      let responseData=[];
       
      for(let i in resAll){
        let v= await resAll[i].json();
        responseData.push( v);
       // console.log(v);
      }
      console.log('allx',responseData)
      callback(responseData);
    });
    //window.testresAll=resAll;
    //console.log('all-result',resAll);
   
      //console.log('all-result',resAll);
      // let responseData=[];
      // for(let i in resAll){
      //   let v=await resAll[i].json();
      //   responseData.push(await v);
      //  // console.log(v);
      // }
     // console.log('all',resAll);
   
   // return responseData;
    // for(let i in resAll){
    //   let v=await resAll[i].json();
    //   responseData.push(v);
    // }
  },
  getAll:async function(urls,token,callback) {
    let headers={
      "Accept": "application/json",
      "Content-Type": "application/json", // request content type
    };
    if(token){
      headers['Authorization']='Bearer '+token
    }
    try {
      let getFetches=()=>{
        let arr=[];
        for(let i in urls){
          arr.push(fetch(urls[i], ApiClient.opt(undefined,token)).then((response) => response.json()),);
        }
        return arr;
      }
      // Promise.all() lets us coalesce multiple promises into a single super-promise
      var data = await Promise.all(getFetches());

    console.log('test-x',data);

    } catch (error) {
      console.log(error);
    }
    if(callback){
      callback(data);
    }
    return await data;
  },
  post: async function (url, data, token, callback) {
    let headers={
      "Accept": "application/json",
      "Content-Type": "application/json", // request content type
    };
    if(token){
      headers['Authorization']='Bearer '+token
    }
    const response = await fetch(url, {
      method: "POST", // *Type of request GET, POST, PUT, DELETE
      mode: "cors", // Type of mode of the request
      cache: "no-cache", // options like default, no-cache, reload, force-cache
      credentials: "same-origin", // options like include, *same-origin, omit
      headers: headers,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "origin", // no-referrer, *client
      body: JSON.stringify(data), // Attach body with the request
    });
    let _data = await response.json();
    if (callback) {
      callback(_data);
    } else {
      return _data;
    }
  },
  postForm: function (url, data, method = "post") {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement("form");
    form.method = method;
    form.action = url;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          for (let i in data[key]) {
            for (let j in data[key][i]) {
              const hiddenField = document.createElement("input");
              hiddenField.type = "hidden";
              hiddenField.name = key + "[" + i + "][" + j + "]";
              hiddenField.value = data[key][i][j];

              form.appendChild(hiddenField);
            }
          }
        } else {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = data[key];

          form.appendChild(hiddenField);
        }
      }
    }

    document.body.appendChild(form);
    form.submit();
  },
  downloadFile: function (file_path) {
    var a = document.createElement("A");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
  },
};
export default ApiClient;
