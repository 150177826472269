import React, { useState, useEffect } from "react";
import "./App.css";
import { Nav} from "./pages/Nav";
import QrReader from "./parts/QrReader";
import { Link, Modal, notificationStyle, Loading,FormGroup,FormItem} from "@wfp/ui";
import {
  FaMap,
  FaListUl,
  FaInfoCircle,
  FaSatelliteDish,
  FaQrcode,
  FaQuestionCircle,
  FaBoxes,
  FaBook,
  FaUserCog,
  FaBookOpen,
  FaCode,
  FaPlusSquare,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { Entity } from "thematrix-entities";
import Helper from "./lib/Helper";
import Loader from "./parts/Loader";
import { MessageManger } from "./parts/MessageManager";
import QuickHelp from "./parts/QuickHelp";
import LeftMenu from "./parts/LeftMenu";


/**
 * App Module for main routing
 * @author Kyaw Zin Soe
 * @datetime 10-Mar-2022
 */

/**
 * App component
 *
 * @param {object} props
 */
const App = (props) => {
  const params = new URLSearchParams(window.location.search);
  var store = require("store");

  const [loading, setLoading] = useState(false);
  const [modalHeading, setModalHeading] = useState("Confirmation");
  const [modalPrimary, setModalPrimary] = useState("OK");
  const [modalSecondary, setmodalSecondary] = useState("Cancel");
  const [modalSecondaryDisabled, setModalSecondaryDisabled] = useState(true);
  const [modalLabel, setModalLabel] = useState("");
  const [modalMessage, setModalMessage] = useState("content");
  const [modalOpen, setModalOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [confirmModal, setConfirmModal] = useState({});
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({});
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertModalData, setAlertModalData] = useState({});
  const [quickHelpOpen, setQuickHelpOpen] = useState(false);
  const [navState, setNavState] = useState("open");
  const [closeMenu, setCloseMenu] = useState(false);
  const [settings, setSettings] = useState(null);
  const [eUser, setEUser] = useState(null);
  const [developerMode, setDeveloperMode] = useState(false);
  const [startScan, setStartScan] = useState(false);
  const [simResetTime,setSimResetTime] = useState(0);
  const [simWaitMode,setSimWaitMode] = useState(false);
  const [srfSelectModalOpen,setSrfSelectModalOpen] = useState(false);  
  const [requestType,setRequestType] = useState(1);
  const [targetType,setTargetType] = useState(1);
  const [alertModal, setAlertModal] = useState({
    title: "",
    message: "",
    open: false,
    show: (ttl, msg, onYes, yesLabel) => {
      alertModalData.title = ttl;
      alertModalData.message = msg;
      setAlertModalOpen(true);
      yesLabel = !yesLabel ? "OK" : yesLabel;
      alertModalData.yesLabel = yesLabel;
      alertModalData.onYes = () => {
        setAlertModalOpen(false);
        if (onYes && typeof onYes === "function") onYes();
      };

      setAlertModalData({ ...alertModalData });
    },
  });

  const [srfStep,setSrfStep]=useState(1);

  /**
   * function that provides logo for module 
   *
   * @param {string} m //module name
   */
  const getLogo = function (m) {
    switch (m) {
      case "form":
        return <FaPlusSquare className="nav-icon" />;
      case "map":
        return <FaBoxes className="nav-icon" />;
      case "logistics":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            className="nav-icon"
            viewBox="0 0 47.989 39"
          >
            <g fill="#ffffff" data-name="Layer 2">
              <path d="M25.994 37.88a1.013 1.013 0 0 0-.054-.19.997.997 0 0 0-.052-.177 1.02 1.02 0 0 0-.08-.104.975.975 0 0 0-.163-.2l-.008-.01-3.654-3.023V18.751l3.016.719v-6.85l-3.016-1.418v-8.21a2.992 2.992 0 0 0-5.984 0V11.2L1.22 18.16a1.96 1.96 0 0 0-.94.83.64.64 0 0 0-.08.14.6.6 0 0 0-.05.12.49.49 0 0 0-.06.17 1.913 1.913 0 0 0-.04 1.01c-.01.01 0 .02 0 .03a.638.638 0 0 0 .06.18.703.703 0 0 0 .08.2 1.902 1.902 0 0 0 .8.88.64.64 0 0 0 .14.08.6.6 0 0 0 .12.05.49.49 0 0 0 .17.06A1.899 1.899 0 0 0 2 22a2.181 2.181 0 0 0 .43-.05h.03L16 18.76v15.422l-3.638 3.017-.008.01a.987.987 0 0 0-.158.195 1.032 1.032 0 0 0-.085.11 20.126 20.126 0 0 0-.106.367 1.041 1.041 0 0 0 .013.127.983.983 0 0 0 .03.26l.002.013a7.457 7.457 0 0 1 .18.326l.01.008a.981.981 0 0 0 .177.143 1.033 1.033 0 0 0 .13.1 1.006 1.006 0 0 0 .098.03 1 1 0 0 0 .268.075 1.028 1.028 0 0 0 .109-.01.995.995 0 0 0 .278-.033l.012-.001 5.688-1.867 5.667 1.86.022.007a.932.932 0 0 0 .685-.032.967.967 0 0 0 .093-.047.986.986 0 0 0 .261-.201c.013-.014.03-.019.043-.033.004-.006.005-.013.01-.019a.99.99 0 0 0 .17-.306v-.013a.983.983 0 0 0 .032-.265 1.033 1.033 0 0 0 .012-.123zM30.21 16.58a6.792 6.792 0 0 1 2.07-.54h.01A5.523 5.523 0 0 1 33 16h9a6.97 6.97 0 0 1 5 2.11V12a1.993 1.993 0 0 0-2-2H30a1.993 1.993 0 0 0-2 2v6.11a6.778 6.778 0 0 1 2-1.43 2.007 2.007 0 0 1 .21-.1z" />
              <path d="M47 26v-3a5.002 5.002 0 0 0-5-5h-9a4.941 4.941 0 0 0-3 1 1.74 1.74 0 0 0-.3.25A4.962 4.962 0 0 0 28 23v3a1.003 1.003 0 0 0-1 1v8a1.003 1.003 0 0 0 1 1h1v1.5a1.5 1.5 0 0 0 3 0V36h11v1.5a1.5 1.5 0 0 0 3 0V36h.99a1.003 1.003 0 0 0 1-1v-8a1 1 0 0 0-.99-1zm-16-3a2.007 2.007 0 0 1 1.2-1.83h.01A1.9 1.9 0 0 1 33 21h9a2.014 2.014 0 0 1 1.94 1.52A1.854 1.854 0 0 1 44 23v3H31zm.5 9a1.5 1.5 0 1 1 1.5-1.5 1.498 1.498 0 0 1-1.5 1.5zm12 0a1.5 1.5 0 1 1 1.5-1.5 1.498 1.498 0 0 1-1.5 1.5z" />
            </g>
          </svg>
        );
      case "track":
        return <FaSatelliteDish className="nav-icon" />;
      case "assessment":
        return <FaListUl className="nav-icon" />;
      case "library":
        return <FaBook className="nav-icon" />;
      case "admin":
        return <FaUserCog className="nav-icon" />;
      case "qr":
        return <FaQrcode className="nav-icon" />;
      case "bell":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            className="nav-icon"
            viewBox="0 0 48 48"
          >
            <g fill="#ffffff" data-name="Layer 2">
              <path d="M43.42 32H43v-3a5.002 5.002 0 0 0-5-5h-5v-2.99A3.013 3.013 0 0 0 29.99 18H3.01A3.013 3.013 0 0 0 0 21.01V44h5a5.974 5.974 0 0 1 2.73-5.03A6 6 0 0 1 17 44h12a6 6 0 0 1 12 0h5.47A1.524 1.524 0 0 0 48 42.47v-5.89A4.58 4.58 0 0 0 43.42 32zM40 32h-7v-5h4.11A2.885 2.885 0 0 1 40 29.89z" />
              <path d="M11 40a4 4 0 1 0 4 4 3.989 3.989 0 0 0-4-4zM35 40a4 4 0 1 0 4 4 3.99 3.99 0 0 0-4-4zM1.5 10h41.252l-4.35 4.35a1.552 1.552 0 1 0 2.195 2.195l6.948-6.947a1.552 1.552 0 0 0 0-2.195L40.597.455a1.552 1.552 0 0 0-2.195 2.195L42.752 7H1.5a1.5 1.5 0 0 0 0 3z" />
            </g>
          </svg>
        );
      case "info":
        return <FaInfoCircle className="nav-icon" />;
      case "help":
        return <FaQuestionCircle className="nav-icon" />;
      case "terms":
        return <FaListUl className="nav-icon" />;
      case "training":
        return <FaBookOpen className="nav-icon" />;
      default:
        return <FaMap />;
    }
  };

  /**
 * function to show modal dialog
 *
 * @param {string} heading
 * @param {string} message
 * @param {string} action //handler
 * @param {string} lbl //button label
 */
  const showModal = function (heading, message, action, lbl) {
    alertModal.show(heading, message, action, lbl ? lbl : "OK");
  };

  /**
   * function to submit the modal form
   *
   * @param {object} arg
   */
  const onModalSubmit = (arg) => {
    setModalOpen(false);
  };

  /**
   * function to show notification
   *
   * if degree property of noti object is dialog, it will show dialog
   * else, it will show toast message.
   * 
   * @param {object} noti
   */
  const showNoti = function (noti) {
    switch (noti.degree) {
      case "normal":
        toast(noti.message);
        break;
      case "danger":
        toast.warn(noti.message);
        break;
      case "deny":
        toast.warn(noti.message);
        break;
      case "dialog":
        showModal(noti.heading, noti.message);
        break;
      default:
        toast(noti.message);
        break;
    }
  };

  /**
   * function to call when modal is closed
   *
   */
  const onModalClose = () => {
    setModalOpen(false);
  };

  /**
   * function to load logged in user
   *
   */
  const loadUser = async function () {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const response = await fetch("/action/auth/current", requestOptions);
    const responseN = await fetch("/action/message", requestOptions);
    const res = await response.json();
    const resN = await responseN.json();
    let noti = resN.data;
    if (noti.message != "") {
      showNoti(noti);
    }
    if (res.data.country_id < 1) {

    }
    store.set("user", res.data);
    let eU = new Entity(res.data, {
      init: function () {
        if (this.first_name) this.name = this.first_name + " " + this.last_name;
        else this.name = "";
      },
      routesLoaded: function (routes) {
        this.route = routes[0];
        this.subRoute = routes[1];
        if (this.route === "emergencies" && this.subRoute === "form") {
          if (routes.length > 3 && (routes[4] == "list" || routes[4] == "view")) {
          } else {
            this.actionLink = "form";
          }
        } else if (!this._isGuest() && !window.isMobileView()) {
          this.actionLink = <Link href="/emergencies/form/srf">New SRF</Link>;
        } else {
          this.actionLink = null;
        }
        this.loaded = true;
      },
      getActionLink: function (routes) {
        this._routesLoaded(routes);
        return this.actionLink;
      },
      doQRScan: function () {

        window.cordova.plugins.barcodeScanner.scan(
          function (result) {

            let url = result.text.replace("cdemacls:", window.location.protocol);
            console.log(url);
            if (url.includes(window.location.host)) {
              setLoading(true);
              setTimeout(function () {
                window.location.href = url;
              }, 50);

            } else {
              setLoading(false);
              if (!result.text || result.text == "")
                alert(
                  MessageManger.title("mobile_qr_invalid") +
                  ", " +
                  MessageManger.message("mobile_qr_fail")
                );
            }
          },
          function (error) {
            console.log(error);
            setLoading(false);
            alert(
              MessageManger.title("mobile_qr_invalid") +
              ", " +
              MessageManger.message("mobile_qr_fail", { error: error })
            );
            // alert("Scanning failed: " + error);
          },
          {
            preferFrontCamera: false, // iOS and Android
            showFlipCameraButton: true, // iOS and Android
            showTorchButton: true, // iOS and Android
            torchOn: true, // Android, launch with the torch switched on (if available)
            saveHistory: true, // Android, save scan history (default false)
            prompt: "Place a barcode inside the scan area", // Android
            resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
            orientation: "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
            disableAnimations: true, // iOS
            disableSuccessBeep: false, // iOS and Android
          }
        );
      },
      isPublic: function () {
        return true;
      },
      isGuest: function () {
        // console.log('uc',this);
        return this.id === undefined || this.role_id < 1;
      },
      isUser: function () {
        return this.role_key==='u';
      },
      isSuper: function () {
        return this.role_key=='su';
      },
      isOperator: function () {
        return this.role_key=='o';
      },
      isSuperOperator: function () {
        return this.role_key=='so';
      },
      isReleasingAuthority: function () {
        return this.role_key=='ra';
      },
      isAdmin: function () {
        return this.role_key=='a';
      },
      isSysAdmin: function () {
        return this.role_key=='d';
      },
      isSuperMin: function () {
        return (
          this._isSuper() ||
          this._isOperator() ||
          this._isReleasingAuthority() ||
          this._isSuperOperator() ||
          this._isAdmin() ||
          this._isSysAdmin()
        );
      },
      isOperatorMin: function () {
        return (
          this._isOperator() ||
          this._isSuperOperator() ||
          this._isAdmin() ||
          this._isSysAdmin()
        );
      },
      isOperatorMinOrIsReleasingAuthority:function(){
        return (this._isOperatorMin() || this._isReleasingAuthority())
      },
      isSuperOperatorMin: function () {
        return this._isSuperOperator() || this._isAdmin() || this._isSysAdmin();
      },
      isSuperOperatorMinOrIsReleasingAuthority:function(){
        return (this._isSuperOperatorMin() || this._isReleasingAuthority())
      },
      isAdminMin: function () {
        return this._isAdmin() || this._isSysAdmin();
      },

      isSuperMax: function () {
        return (
          this._isUser() ||
          this._isSuper()
        );
      },
      isOperatorMax: function () {
        return (
          this._isUser() ||
          this._isSuper() ||
          this._isOperator()
        );
      },
      isSuperOperatorMax: function () {
        return (
          this._isUser() ||
          this._isSuper() ||
          this._isOperator() ||
          this._isSuperOperator()
      );
      },
      isAdminMax: function () {
        return(
          this._isUser() ||
          this._isSuper() ||
          this._isOperator() ||
          this._isSuperOperator() ||
          this._isReleasingAuthority() ||
          this._isAdmin()
        );
      },

      isDeveloperModeEnabled: function () {
        return this._isSysAdmin() && developerMode;
      },
      simulation:function(){
        function padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }
        
        function convertMsToTime(milliseconds) {
          let seconds = Math.floor(milliseconds / 1000);
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
        
          seconds = seconds % 60;
          minutes = minutes % 60;
        
          // 👇️ If you don't want to roll hours over, e.g. 24 to 00
          // 👇️ comment (or remove) the line below
          // commenting next line gets you `24:00:00` instead of `00:00:00`
          // or `36:15:31` instead of `12:15:31`, etc.
          hours = hours % 24;
        
          return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds,
          )}`;
        }
        setSimWaitMode(false);
        function timeUntilNextReload() {
          let date=new Date();
          let hr=date.getUTCHours();
          hr=hr%2==0?hr+2:hr+1;
         //hr=hr+1;
          let now=Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                          date.getUTCDate(), date.getUTCHours(),
                          date.getUTCMinutes(), date.getUTCSeconds());
          let next=Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                          date.getUTCDate(), hr,
                          0,0);
          console.log(new Date(now))
          console.log(new Date(next))
          return(next-now)
      }
  
      // function checkForReload() {
      //     const reloadTime = 30 * 1000; // 30 seconds in milliseconds
      //     console.log('checking to reload page');
      //     if (timeUntilNextReload() <=10) {
      //       clearInterval(window.resetInterval);
      //        setTimeout(function(){
      //         window.location.reload(true);
      //        },reloadTime);
              
      //     }
      // }
  
      function checkForResetAlert() {
        console.log('checking to show reset warning');
        
          const warningTime = 5 * 60 * 1000; // 5 minutes in milliseconds
          const reloadTime = 30 * 1000;
          let waitTime=timeUntilNextReload();
          if (!simWaitMode && waitTime <= (warningTime)) { 
              showModal('Warning','The data will reset in less than 5 minutes.!');
              setSimWaitMode(true);
              // Prevent the alert from showing multiple times in the 5-minute window
              setTimeout(function(){
                window.location.reload(true);
               },waitTime+reloadTime);
              //clearInterval(window.resetInterval);
          }
          setSimResetTime(convertMsToTime(waitTime));
          
      }
      console.log('setting reload interval');
      checkForResetAlert()
      window.resetInterval = setInterval(() => {
        console.log('checking reset/reload');
          checkForResetAlert();
          //checkForReload();
      }, 60 * 1000); // Check every minute
      }
    });
    if(res.meta){
      console.log('setting meta data',res.meta);
      eU.meta=res.meta;
      if(eU.meta && eU.meta.simulation){
        console.log('simulation mode activated');
        eU._simulation();
      }
    }
    window.eUser = eU;
    setEUser(eU);
  };

  /**
   * function to load frontend setting json file.
   *
   */
  const loadSettings = async () => {
    let responseSettings = await fetch("/upload/conf/settings.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        pragma: "no-cache",
        "cache-control": "no-cache",
      },
    });
    let resSettings = await responseSettings.json();

    let responseLocalSettings = await fetch("/app/check_local_doc", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    let resLocalSettings = await responseLocalSettings.json();
    if (resLocalSettings.data.local) {
      resSettings.documentation.base = "/doc/doku.php";
    }
    store.set("settings", resSettings);
    setSettings(resSettings);
  };
  const [didMount, setDidMount] = useState(false);

  /**
 * useEffect hook when the component loads first time
 *
 * @param {array} eSrfs
 */
  useEffect(() => {
    if (window.location.host === 'next.e2e-cdema.com') {
      window.location.href = 'https://preview.cls-cdema.org';
      return;
    }
    setConfirmModal({
      title: "",
      message: "",
      open: false,
      show: (ttl, msg, onYes, onNo, disabledNo, yesLabel) => {
        confirmModalData.title = ttl;
        confirmModalData.message = msg;
        setConfirmModalOpen(true);
        yesLabel = !yesLabel ? "Yes" : yesLabel;
        confirmModalData.yesLabel = yesLabel;
        confirmModalData.disableNo =
          disabledNo === undefined || disabledNo === true;
        confirmModalData.onYes = () => {
          onYes();

          setConfirmModalOpen(false);
        };
        confirmModalData.onNo = () => {
          if (onNo) onNo();
          setConfirmModalOpen(false);
        };
        setConfirmModalData({ ...confirmModalData });
      },
    });

    loadSettings();
    loadUser();
    
    let dcheck = store.get('developer');
    setDeveloperMode(dcheck ? true : false);
    console.log('dcheck', dcheck);
    if (undefined === dcheck) {
      console.log('setting developer mode');
      store.set('developer', false);
    }

  }, []);


  /**
 * useEffect hook to call when the logged in user and routes are ready
 *
 * @param {object} eUser //logged in user
 * @param {object} props.match.params //routes
 * 
 */
  useEffect(() => {
    setDidMount(true);

    let _routes = props.match.params[0].split("/");
    setRoutes(_routes);
    if (!eUser || eUser.loaded) return;
    eUser._routesLoaded(_routes);
    setEUser({ ...eUser });

    return () => setDidMount(false);
  }, [eUser, props.match.params]);

  /**
   * function to toggle nav view on or off
   *
   */
  const toggleNav = function () {
    let nav = navState === "open" ? "collapsed" : "open";
    setNavState(nav);
  };

  /**
   * function to load module via loader
   *
   */
  const switchRoute = function () {
    let route = routes[0];
    let sub = routes[1];
    let sub2 = routes.length>2?routes[2]:"";

    switch (route) {
      default:
        return (
          <Loader
          
            developerMode={developerMode}
            offline={false}
            routes={routes}
            route={route}
            sub={sub}
            sub2={sub2}
            settings={settings}
            showNoti={showNoti}
            setLoading={setLoading}
            showModal={showModal}
            setModalOpen={setModalOpen}
            confirmModal={confirmModal}
            alertModal={alertModal}
            eUser={eUser}
          />
        );
    }
  };
  if (!didMount) {
    return null;
  }
const handleSrfAction=()=>{
  setSrfStep(1);
  setSrfSelectModalOpen(true);
}
  /**
   * return rendered view 
   *
   */
  return (
    <>
      {eUser !== null ? (
        <div>
          <Nav
            enable={
              props.match.params[0] !== "login" &&
              props.match.params[0] !== "register"
            }
            closemenu={closeMenu ? "true" : undefined}
            setNavState={setNavState}
            route={props.match.params[0]}
            eUser={eUser}
            navToggle={toggleNav.bind(this)}
            mobile={window.isMobileCLS()}
            simResetTime={simResetTime}
            simWaitMode={simWaitMode}
            handleSrfAction={handleSrfAction}
          />
          <div className="main-wrap color__ui-05 p-r">
            <LeftMenu
              handleSrfAction={handleSrfAction}
              eUser={eUser}
              enable={
                props.match.params[0] !== "login" &&
                props.match.params[0] !== "register"
              }
              navState={navState}
              route={props.match.params[0]}
            />
            <div
              className={"main " + navState}
              closemenu={closeMenu ? "true" : undefined}
            // onClick={() => {
            //   setCloseMenu(true);

            //   if (window.isMobileView()) {
            //     setNavState("open");
            //   }
            // }}
            >
              {routes.length > 0 && eUser.loaded ? switchRoute() : <></>}
            </div>
            {quickHelpOpen ? (
              <QuickHelp 
              routes={routes}
              setLoading={setLoading}
              quickHelpOpen={quickHelpOpen}
              setQuickHelpOpen={setQuickHelpOpen}
              settings={settings}
              />
            ) : (
              <></>
            )}
          </div>
          {modalOpen ? (
            <Modal
              onRequestClose={onModalClose}
              className={
                "alert-box " + (modalSecondaryDisabled ? "sec-disabled" : "")
              }
              hideClose={false}
              onRequestSubmit={onModalSubmit}
              onSecondarySubmit={onModalClose}
              open={modalOpen}
              modalHeading={modalHeading}
              modalLabel={modalLabel}
              primaryButtonText={modalPrimary}
              secondaryButtonText={modalSecondary}
              secondaryButtonDisabled={modalSecondaryDisabled}
            >
              <p className="wfp--modal-content__text">
                {Array.isArray(modalMessage) ? (
                  <>
                    {modalMessage.map((m, idx) => {
                      return <p key={idx}>{m}</p>;
                    })}
                  </>
                ) : (
                  <>{modalMessage}</>
                )}
              </p>
            </Modal>
          ) : (
            <></>
          )}
          {confirmModalOpen ? (
            <Modal
              onRequestClose={() => {
                setConfirmModalOpen(false);
              }}
              className={"alert-box "}
              hideClose={confirmModalData.disableNo}
              onRequestSubmit={() => {
                confirmModalData.onYes();
              }}
              onSecondarySubmit={() => {
                confirmModalData.onNo();
              }}
              open={confirmModalOpen || false}
              modalHeading={
                confirmModalData.title ? confirmModalData.title : ""
              }
              modalLabel={confirmModalData.label ? confirmModalData.label : ""}
              primaryButtonText={
                confirmModalData.yesLabel ? confirmModalData.yesLabel : "Yes"
              }
              secondaryButtonText={
                confirmModalData.noLabel ? confirmModalData.noLabel : "No"
              }
              secondaryButtonDisabled={false && confirmModalData.disableNo}
            >
              <p className="wfp--modal-content__text">
                {confirmModalData.message ? confirmModalData.message : ""}
              </p>
            </Modal>
          ) : (
            <></>
          )}
          {alertModalOpen ? (
            <Modal
              onRequestClose={() => {
                if (
                  alertModalData.onYes &&
                  typeof alertModalData.onYes === "function"
                ) {
                  alertModalData.onYes();
                }

                setAlertModalOpen(false);
              }}
              className={"alert-box "}
              onRequestSubmit={() => {
                if (
                  alertModalData.onYes &&
                  typeof alertModalData.onYes === "function"
                ) {
                  alertModalData.onYes();
                }
                setAlertModalOpen(false);
              }}
              open={alertModalOpen || false}
              modalHeading={alertModalData.title ? alertModalData.title : ""}
              modalLabel={alertModalData.label ? alertModalData.label : ""}
              primaryButtonText={
                alertModalData.yesLabel ? alertModalData.yesLabel : "Yes"
              }
            >
              <p className="wfp--modal-content__text">
                {alertModalData.message ? alertModalData.message : ""}
              </p>
            </Modal>
          ) : (
            <></>
          )}

          <Loading active={loading} withOverlay={true}></Loading>
          <ToastContainer {...notificationStyle} hideProgressBar={true} />
        </div>
      ) : (
        <></>
      )}

      {eUser &&
        routes &&
        settings &&
        settings.documentation &&
        settings.documentation[routes[0]] ? (
        <>
          {/*quickHelpOpen ? (
            <Modal
              className="wide-box mid-dialog wide-box-f"
              onRequestClose={() => {
                setQuickHelpOpen(false);
              }}
              hideClose={false}
              open={quickHelpOpen}
              passiveModal
            >
              {quickHelpOpen ? <iframe title='help' src={quickHelpUrl}></iframe> : <></>}
            </Modal>
          ) : (
            <></>
          )*/}
          {startScan ?
            <QrReader
              eUser={eUser}
              setLoading={setLoading}
              setStartScan={setStartScan}
              startScan={startScan}
            />
            : <></>}
          <div className='q-wrap'>
            {(window.isMobileView() && eUser._isOperatorMin()) ? <div
              className="q-scan"
              onClick={() => {
                
                setStartScan(true);
              }}
            >
              <FaQrcode />
            </div> : <></>}
            <div
              className="q-help"
              onClick={() => {
                //quickHelp();
                setQuickHelpOpen(true);
              }}
            >
              <FaQuestionCircle />
            </div>

            {eUser._isSysAdmin() ?
              <div
                className={"q-develop " + (developerMode ? 'on' : '')}
                onClick={() => {
                  let _p=developerMode;
                  store.set('developer', !_p);
                  showNoti({ 'message': 'Developer Mode ' + (_p ? 'Disabled' : 'Enabled') })
                  setDeveloperMode(!_p);
                }}
              >
                <FaCode />
              </div> : <></>
            }

          </div>
        </>
      ) : (
        <></>
      )}
    {srfSelectModalOpen?
    <Modal
    onRequestClose={() => {
      setSrfSelectModalOpen(false);
    }}
    className={"alert-box "}
    onRequestSubmit={() => {
      if(requestType==2 && srfStep==1){
        setSrfStep(2);return;
      }
      // go donation fomr
      setSrfSelectModalOpen(false);
      //open srf
      window.location.href='/emergencies/form/srf?type='+(requestType==1?'srf':'donation')+'&target='+(targetType==1?'internal':'external');
    }}
    open={srfSelectModalOpen || false}
    modalHeading="New Service Request"
    primaryButtonText={
      "Next"
    }
  >
    <div className="wfp--modal-content__text">
    <label htmlFor="s_warehouses" className="wfp--label">
            {srfStep==1?"What do you want to do?":"Will the donation be to:"}
          </label>
          {srfStep==1?<div className=" button-box row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <FormGroup>
              <FormItem>
                <span><input id='srf_type1' type='radio' name='srf_type' value='1' checked={requestType==1} onChange={(e)=>{
                  setRequestType(e.target.value);
                }} /> <label for='srf_type1'>Request Transport/Storage of relief items</label></span>
                </FormItem>
              </FormGroup>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12">
            <FormGroup>
             
              <FormItem>
                <span><input type='radio' id='srf_type2' name='srf_type' value='2'  checked={requestType==2} onChange={(e)=>{
                  setRequestType(e.target.value);
                }}   /> <label for='srf_type2'>Donate items from stock</label></span>
                </FormItem>
              </FormGroup>
              </div>
            </div>:<></>}
            {requestType==2 && srfStep==2?<>
          <div className=" row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <FormGroup>
             
              <FormItem>
                <span><input id='donation_type1' type='radio' name='donation_type' value='1' checked={targetType==1} onChange={(e)=>{
                  setTargetType(e.target.value);
                }}/> <label for='donation_type1' clasName='wfp--label'>a registered organisation</label></span>
                </FormItem>
              </FormGroup>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12">
            <FormGroup>
             
              <FormItem>
                <span><input type='radio' id='donation_type2' name='donation_type' value='2'  checked={targetType==2} onChange={(e)=>{
                  setTargetType(e.target.value);
                }} /> <label for='donation_type2' clasName='wfp--label'>an unregistered person or organisation</label></span>
                </FormItem>
              </FormGroup>
              </div>
            </div>
            </>:<></>}

    </div>
  </Modal>
    :<></>}
    </>
  );
}

export default App;
