import React, { useState } from "react";
import {
  FaListUl,
  FaInfoCircle,
  FaSatelliteDish,
  FaQuestionCircle,
  FaBoxes,
  FaBook,
  FaUserCog,
  FaBookOpen,
  FaCaretRight,
  FaCaretDown,
  FaFile,
  FaFolder,
} from "react-icons/fa";

const MenuItems = (props) => {
  let _menus = props.menus ? props.menus : props.baseMenus;
  return (
    <>
      {props.collapsed ? (
        <></>
      ) : (
        <>
          {_menus.map((menu, index) => {
            let mbC = !menu.mobile ? " lg-sc-only" : "";
            let lvlC =
              props.level && props.level > 1 ? "m-lvl-" + props.level : "";
            if (menu.onlyMobile && !window.isMobileCLS()) return <></>;
            return (
              <span key={index}>
                {props.eUser && props.eUser["_" + menu.auth]() ? (
                  <>
                    {" "}
                    <li
                      className={
                        (((props.level === 1 &&
                          props.route.startsWith(menu.route)) ||
                          window.location.pathname == menu.path) &&
                        !menu.onClick
                          ? "active" + mbC
                          : mbC) +
                        " " +
                        lvlC +
                        " " +
                        (menu.className ? menu.className : "")
                      }
                    >
                      {menu.subs ? (
                        <a
                          className="pr-vm"
                          onClick={() => {
                            if (!props.parentIndex) {
                              props.baseMenus[index].collapsed =
                                !props.baseMenus[index].collapsed;
                              props.setMenus([...props.baseMenus]);
                            }
                          }}
                        >
                          {menu.icon}{" "}
                          <p>
                            {menu.title}{" "}
                            {menu.collapsed ? (
                              <FaCaretRight />
                            ) : (
                              <FaCaretDown />
                            )}
                          </p>
                        </a>
                      ) : (
                        <a
                          rel="noreferrer"
                          href={
                            menu.suspended?'/unavailable/soon':(menu.onClick
                              ? null
                              : menu.link
                              ? menu.link
                              : menu.path)
                          }
                          onClick={menu.onClick ? menu.onClick : null}
                          target={menu.link ? "_blank" : null}
                          className="pr-vm"
                        >
                          {menu.icon} <p>{menu.title}</p>
                        </a>
                      )}
                    </li>
                    {menu.subs ? (
                      <MenuItems
                        {...props}
                        menus={menu.subs}
                        parentIndex={index}
                        collapsed={menu.collapsed}
                        level={props.level + 1}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </span>
            );
          })}
        </>
      )}
    </>
  );
};

/**
 * LeftMenu component
 *
 * @param {object} props
 */
const LeftMenu = function (props) {
  const [menus, setMenus] = useState([
    {
      icon: <FaBoxes className="nav-icon" />,
      title: "Stocks",
      route: "stocks",
      path: "/stocks/map",
      active: "",
      mobile: true,
      auth: "isPublic",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          className="nav-icon"
          viewBox="0 0 48 48"
        >
          <g fill="#ffffff" data-name="Layer 2">
            <path d="M43.42 32H43v-3a5.002 5.002 0 0 0-5-5h-5v-2.99A3.013 3.013 0 0 0 29.99 18H3.01A3.013 3.013 0 0 0 0 21.01V44h5a5.974 5.974 0 0 1 2.73-5.03A6 6 0 0 1 17 44h12a6 6 0 0 1 12 0h5.47A1.524 1.524 0 0 0 48 42.47v-5.89A4.58 4.58 0 0 0 43.42 32zM40 32h-7v-5h4.11A2.885 2.885 0 0 1 40 29.89z" />
            <path d="M11 40a4 4 0 1 0 4 4 3.989 3.989 0 0 0-4-4zM35 40a4 4 0 1 0 4 4 3.99 3.99 0 0 0-4-4zM1.5 10h41.252l-4.35 4.35a1.552 1.552 0 1 0 2.195 2.195l6.948-6.947a1.552 1.552 0 0 0 0-2.195L40.597.455a1.552 1.552 0 0 0-2.195 2.195L42.752 7H1.5a1.5 1.5 0 0 0 0 3z" />
          </g>
        </svg>
      ),
      title: "Emergency Logistics",
      route: "emergencies",
      path: "",
      active: "",
      mobile: true,
      auth: "isPublic",
      collapsed: true,
      subs: [
        {
          icon: <FaFile className="nav-icon" />,
          title: "Create Service Request",
          route: "srf",
          onClick: () => {
            props.handleSrfAction();
          },
          active: "",
          mobile: false,
          auth: "isPublic",
        },
        {
          icon: <FaFolder className="nav-icon" />,
          title: "Manage Requests",
          route: "emergencies",
          path: "/emergencies/requests",
          active: "",
          mobile: true,
          auth: "isOperatorMinOrIsReleasingAuthority",
        },
      ],
    },
    {
      icon: <FaListUl className="nav-icon" />,
      title: "Assessments",
      route: "assessments",
      path: "/assessments/logistics",
      active: "",
      mobile: false,
      auth: "isSuperMin",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          className="nav-icon"
          viewBox="0 0 47.989 39"
        >
          <g fill="#ffffff" data-name="Layer 2">
            <path d="M25.994 37.88a1.013 1.013 0 0 0-.054-.19.997.997 0 0 0-.052-.177 1.02 1.02 0 0 0-.08-.104.975.975 0 0 0-.163-.2l-.008-.01-3.654-3.023V18.751l3.016.719v-6.85l-3.016-1.418v-8.21a2.992 2.992 0 0 0-5.984 0V11.2L1.22 18.16a1.96 1.96 0 0 0-.94.83.64.64 0 0 0-.08.14.6.6 0 0 0-.05.12.49.49 0 0 0-.06.17 1.913 1.913 0 0 0-.04 1.01c-.01.01 0 .02 0 .03a.638.638 0 0 0 .06.18.703.703 0 0 0 .08.2 1.902 1.902 0 0 0 .8.88.64.64 0 0 0 .14.08.6.6 0 0 0 .12.05.49.49 0 0 0 .17.06A1.899 1.899 0 0 0 2 22a2.181 2.181 0 0 0 .43-.05h.03L16 18.76v15.422l-3.638 3.017-.008.01a.987.987 0 0 0-.158.195 1.032 1.032 0 0 0-.085.11 20.126 20.126 0 0 0-.106.367 1.041 1.041 0 0 0 .013.127.983.983 0 0 0 .03.26l.002.013a7.457 7.457 0 0 1 .18.326l.01.008a.981.981 0 0 0 .177.143 1.033 1.033 0 0 0 .13.1 1.006 1.006 0 0 0 .098.03 1 1 0 0 0 .268.075 1.028 1.028 0 0 0 .109-.01.995.995 0 0 0 .278-.033l.012-.001 5.688-1.867 5.667 1.86.022.007a.932.932 0 0 0 .685-.032.967.967 0 0 0 .093-.047.986.986 0 0 0 .261-.201c.013-.014.03-.019.043-.033.004-.006.005-.013.01-.019a.99.99 0 0 0 .17-.306v-.013a.983.983 0 0 0 .032-.265 1.033 1.033 0 0 0 .012-.123zM30.21 16.58a6.792 6.792 0 0 1 2.07-.54h.01A5.523 5.523 0 0 1 33 16h9a6.97 6.97 0 0 1 5 2.11V12a1.993 1.993 0 0 0-2-2H30a1.993 1.993 0 0 0-2 2v6.11a6.778 6.778 0 0 1 2-1.43 2.007 2.007 0 0 1 .21-.1z" />
            <path d="M47 26v-3a5.002 5.002 0 0 0-5-5h-9a4.941 4.941 0 0 0-3 1 1.74 1.74 0 0 0-.3.25A4.962 4.962 0 0 0 28 23v3a1.003 1.003 0 0 0-1 1v8a1.003 1.003 0 0 0 1 1h1v1.5a1.5 1.5 0 0 0 3 0V36h11v1.5a1.5 1.5 0 0 0 3 0V36h.99a1.003 1.003 0 0 0 1-1v-8a1 1 0 0 0-.99-1zm-16-3a2.007 2.007 0 0 1 1.2-1.83h.01A1.9 1.9 0 0 1 33 21h9a2.014 2.014 0 0 1 1.94 1.52A1.854 1.854 0 0 1 44 23v3H31zm.5 9a1.5 1.5 0 1 1 1.5-1.5 1.498 1.498 0 0 1-1.5 1.5zm12 0a1.5 1.5 0 1 1 1.5-1.5 1.498 1.498 0 0 1-1.5 1.5z" />
          </g>
        </svg>
      ),
      title: "Logistics Capabilities",
      route: "logistics",
      path: "/logistics/map",
      active: "",
      mobile: true,
      auth: "isPublic",
    },
    {
      icon: <FaSatelliteDish className="nav-icon" />,
      title: "Active Tracking",
      route: "trackings",
      path: "/trackings",
      active: "",
      mobile: true,
      auth: "isOperatorMin",
    },
    // {icon:<FaBoxes className="nav-icon" />,title:"Needs",route:'needs',path:"/needs/map",active:'',mobile:true,auth:'isPublic'},
    {
      icon: <FaBookOpen className="nav-icon" />,
      title: "Remote Training",
      route: "training",
      path: "/training",
      link: "https://remotetraining.moodle.school/",
      active: "",
      mobile: true,
      auth: "isPublic",
      suspended:true,
    },
    {
      icon: <FaBook className="nav-icon" />,
      title: "Library",
      route: "library",
      path: "/library",
      active: "",
      mobile: true,
      auth: "isUser",
    },
    {
      icon: <FaUserCog className="nav-icon" />,
      title: "Admin",
      route: "admin",
      path: "/admin",
      active: "",
      mobile: true,
      auth: "isAdminMin",
      className: "phone-hide",
    },
    {
      icon: <FaInfoCircle className="nav-icon" />,
      title: "About",
      route: "about",
      path: "/about",
      active: "",
      mobile: true,
      auth: "isPublic",
    },
    {
      icon: <FaQuestionCircle className="nav-icon" />,
      title: "Help",
      route: "help",
      path: "/help",
      active: "",
      mobile: true,
      auth: "isPublic",
    },
    {
      icon: <FaListUl className="nav-icon" />,
      title: "Terms of Service",
      route: "terms",
      path: "/terms",
      active: "",
      mobile: true,
      auth: "isPublic",
    },
  ]);
  /**
   * return rendered view
   *
   */
  if (props.enable) {
    return (
      <ul className={"sidebar " + props.navState}>
        <MenuItems {...props} baseMenus={menus} level={1} setMenus={setMenus} />
      </ul>
    );
  } else {
    return <></>;
  }
};
export default LeftMenu;
