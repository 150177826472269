import React, { useState, useEffect } from "react";
import { MessageManger } from "./MessageManager";
import QrScanner from "react-qr-reader";
import { Modal,TextInput,Button,FormGroup } from "@wfp/ui";
import ApiClient from "../lib/ApiClient";
import { Entity } from "thematrix-entities";

/**
 * Parts/Deprecated module to show deprecated page.
 * @author Kyaw Zin Soe
 * @datetime 7-Apr-2023
 */

/**
 * Deprecated component
 *
 * @param {object} props
 */
function Deprecated (props) {
   

    return (
        <>
        <br />
        <br />
        <h2>
        {(props.heading?props.heading:'Deprecated')}
      </h2>
      <hr />
      <label>{(props.message?props.message:'This feature is not available now')}</label>
      <br />
      {props.children}
      </>
    );
}

export default Deprecated;