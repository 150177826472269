import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Modal } from '@wfp/ui';
import XLSX from 'xlsx';

/**
 * Lib/Helper object to use helper functions
 * @author Kyaw Zin Soe
 * @datetime 3-Mar-2022
 */

/**
 * Helper object
 *
 */
const Helper = {
    /**
     * prepare dropdown select options
     *
     * @param {object} data
     * @param {object} matches
     * @param {object} topping
     */
    prepareOptions: function (data, matches, topping) {
        let rst = [];
        if (topping) {
            rst.push(topping);
        }
        for (let i in data) {
            rst.push({ value: data[i][matches.value], label: data[i][matches.label] });
        }
        return rst;
    },

    /**
     * function which provides the mapping of switch block to loop switch in react view
     *
     * @param {array} funcList
     * @param {string} key
     */
    switchMap: function (funcList, key) {

        if (funcList[key]) {
            let rst = [];
            rst.push(funcList[key]());
            return rst;
        } else {
            for (let i in funcList) {
                let kc = i.split(',');
                if (kc.length > 1) {
                    if (kc.includes(key)) {
                        let rst = [];
                        rst.push(funcList[i]());
                        return rst;
                    } 
                }
            }
        }
        return [];
    },

    /**
     * function which renders view with callback
     *
     * @param {array} data
     * @param {function} callback
     * 
     * @return {object} result
     */
    render: function (data, callback) {

        let result = [
            callback(data)
        ];
        return result;
    },

    /**
     * function which provides the mapping to loop switch in react view
     *
     * @param {array} funcList
     * @param {string} key
     */
    forMap: function (data, start, end, callback) {

        let rst = [];
        for (let i = start; i <= end; i++) {
            if (!data || data.length == 0)
                rst.push(callback({}, i));
            else
                rst.push(callback(data[i], i));
        }
        return rst;
    },

    /**
     * function which provides the mapping to loop an object in react view
     *
     * @param {object} data
     * @param {function} callback
     */
    objMap: function (data, callback) {

        let rst = [];
        let id = 0;
        for (let i in data) {
            rst.push(callback(data[i], i, id++));
        }
        return rst;
    },

    /**
     * function which filters data with start and end limit
     *
     * @param {object} data
     * @param {integer} start
     * @param {integer} filtered
     * 
     * @return {object} data
     */
    filter: function (data, start, end) {
        if (data.length == 0) return [];
        let filtered = [];
        end = end > data.length - 1 ? data.length - 1 : end;
        for (let i = start; i <= end; i++) {
            filtered.push(data[i]);
        }
        return filtered;
    },

    /**
     * function which sorts object list with field
     *
     * @param {object} data
     * @param {string} field
     * @param {string} mode //string/ number / date
     * @param {string} order //asc or desc
     * 
     * @return {object} data
     */
    sortBy: function (data, field, mode, order) {
        let compare = function (prop, reverse) {
            return function (a, b) {

                if ((a[prop] + '').toLowerCase() < (b[prop] + '').toLowerCase()) return reverse ? 1 : -1;
                if (a[prop] > b[prop]) return reverse ? -1 : 1;
                return 0;
            };
        }
        let compareNum = function (prop, reverse) {
            return function (a, b) {
                if (parseFloat(a[prop] + '') < parseFloat(b[prop] + '')) return reverse ? 1 : -1;
                if (parseFloat(a[prop] + '') > parseFloat(b[prop] + '')) return reverse ? -1 : 1;
                return 0;
            };
        }
        let compareDate = function (prop, reverse) {
            return function (a, b) {
                let df = new Date(a.date) - new Date(b.date);
                return reverse ? (-(df)) : df;
            };
        }
        switch (mode) {
            case 'number':
                return data.sort(compareNum(field, order !== 'asc'));
            default:
                return data.sort(compare(field, order !== 'asc'));
                break;
        }
    },

    /**
     * function which notifies message by degree
     *
     * @param {string} msg
     * @param {string} degree
     */
    notify: function (msg, degree) {
        degree = degree || 'normal';
        switch (degree) {
            case 'normal':
                toast(msg);
                break;
            case 'danger':
                toast.warn(msg);
                break;
            case 'deny':
                toast.warn(msg);
                break;
            default:
                toast(msg);
                break;
        }
    },

    /**
     * function which determines if an array's cell is in list or not
     *
     * @param {array} src
     * @param {array} list
     * 
     * @return {boolean} 
     */
    matchInlist: function (src, list) {
        let _found = false;
        for (let i in src) {
            _found = list.includes(src[i].toLowerCase());
            if (_found) return _found;
        }
        return false;
    },


    /**
     * Component which provides non breaking spaces
     *
     * @param {object} props
     * 
     * @return {object} 
     */
    Nbsp: function (props) {
        return (<>
            {Helper.forMap([], 0, props.total, function (item, index) {
                return <span key={index}>&nbsp;</span>;
            })}
        </>);
    },

    /**
     * Component which provides confirmation modal
     *
     * @param {object} props
     * 
     * @return {object} 
     */

    Confirm: function (props) {


        return (
            <Modal
                onRequestClose={props.onClose}
                hideClose={false}
                onRequestSubmit={props.onOkay}
                onSecondarySubmit={props.onClose}
                open={props.state}
                modalHeading={props.heading}
                modalLabel={props.label}
                primaryButtonText='Okay'
                secondaryButtonText='Cancel'
            >
                <p className="wfp--modal-content__text">
                    {props.message}
                </p>
            </Modal>
        );
    },

    /**
     * Component which layouts a view in center
     *
     * @param {object} props
     * 
     * @return {object} 
     */
    Center: function (props) {
        return (
            <div className={'tb-w '+(props.wrapClassName?props.wrapClassName:'')}>
                <div className={' tb-c v-mid ' + (props.className ? props.className : '')}>
                    {props.children}
                </div>
            </div>
        )
    },


    /**
     * Component which provdes fixing inline issue with CSS
     *
     * @return {object} 
     */
    InlineFixer: function () {
        return (
            <span className='i-fix'>.</span>
        );
    },

    /**
     * Component which parse number strings with decimal points
     *
     * @param {string} nStr
     * @param {integer} lg
     * 
     * @return {number} rv
     */
    parseNum: function (nStr, lg) {
        var rv = "";
        nStr = nStr + '';
        if (!lg) {
            lg = 2;
        }
        for (let i in nStr) {
            if (nStr[i] != "$" && nStr[i] != ",") {
                rv += nStr[i];
            }
        }
        if (rv.includes(".")) {
            rv = parseFloat(rv);
            rv = parseFloat(rv.toFixed(lg));
        } else {
            rv = parseInt(rv);
        }
        return isNaN(rv) ? 0 : rv;
    },

    /**
     * function which shows popup view
     *
     * @param {string} url
     * @param {integer} w //width
     * @param {integer} h //height
     * 
     * @return {boolean}
     */
    popup: function (url, w, h) {
        w = !w ? 800 : w;
        h = !h ? 600 : h;
        let newwindow = window.open(url, 'name', 'height=' + h + ',width=' + w);
        if (window.focus) { newwindow.focus() }
        return false;

    },

    /**
     * function format column of worksheet
     *
     * @param {object} worksheet
     * @param {integer} col
     * @param {format} fmt
     * 
     * @return {boolean}
     */
    formatColumn: (worksheet, col, fmt) => {
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        // note: range.s.r + 1 skips the header row
        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: col })
            if (worksheet[ref] && worksheet[ref].t === 'n') {
                worksheet[ref].z = fmt
            }
        }
        return worksheet;
    },

    /**
     * function which download excel workbook
     *
     * @param {array} data
     * @param {string} fileName
     * @param {string} sheetName
     * @param {object} opts
     * @param {function} callback
     * 
     * @return {boolean}
     */
    downloadExcel(ws_data, fileName, sheetName, opts, callback) {
        opts = opts ? opts : {};
        var new_workbook = XLSX.utils.book_new();
        let formats = opts.formats;
        let columns = opts.columns;
        let formula = opts.formula;
        let autoFilters = opts.autoFilters;
        if (formula) {
            for (let i in ws_data) {
                for (let j in ws_data[i]) {
                    if (ws_data[i][j] && ws_data[i][j].charAt && ws_data[i][j].charAt(0) == '=') {
                        ws_data[i][j] = { f: ws_data[i][j].slice(1) }
                    }
                }
            }
        }
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        if (formats) {
             //alert('here')
            for (let i in formats) {
                console.log(XLSX.utils.decode_col(i), formats[i])
               ws= Helper.formatColumn(ws, XLSX.utils.decode_col(i), formats[i]);
            }
        }
        if (autoFilters) {
            ws['!autofilter'] = { ref: autoFilters };
        }
        if (columns) {
            var wscols = columns;
            ws['!cols'] = wscols;
        }
        XLSX.utils.book_append_sheet(new_workbook, ws, sheetName);


        let promise = new Promise(function (resolve, reject) {

            let out = XLSX.writeFile(new_workbook, fileName);
            setTimeout(function () {
                resolve(out);
            }, 2000);

        });
        promise.then((s) => {
            if (callback) {
                callback();
            }
        })
    },

    /**
     * function which provies url parameter by name
     *
     * @param {string} name
     * @param {integer} url
     * 
     * @return {string}
     */
    getParameterByName: function (name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },


    /**
     * function which converts year-month-date string to moment string
     *
     * @param {string} ymd
     * 
     * @return {string}
     */
    ymdToMomentString: function (ymd) {
        let tryD = ymd.split('-');
        if (!tryD) return null;
        return tryD[2] + '/' + tryD[1] + '/' + tryD[0];
    },

    /**
     * function which prepares html option list to value array
     *
     * @param {array} options
     * @param {boolean} toString
     * 
     * @return {array}
     */
    optionsToValueArray: function (options, toString = false) {
        if (!options) return [];
        let _arr = [];
        for (let i in options) {
            _arr.push(options[i].value);
        }
        return toString ? _arr.join(',') : _arr;
    },


    /**
     * function which provide linebreak
     *
     * @param {string} str
     * @param {string} brk
     * 
     * @return {string}
     */
    breakLine: (str, brk = '\n') => {
        if (!str) return '';
        let arr = str.split(brk);
        let fmt = [];
        for (let j in arr) {
            fmt.push(arr[j]);
            fmt.push(<br />);
        }
        console.log('dec-chk', fmt);
        return fmt;
    },

    /**
     * function which merges objects
     *
     * @param {object} target
     * @param {object} source
     * 
     * @return {object}
     */
    merge: (target, source) => {
        // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
        for (const key of Object.keys(source)) {
          if (source[key] instanceof Object) Object.assign(source[key], Helper.merge(target[key], source[key]))
        }
      
        // Join `target` and modified `source`
        Object.assign(target || {}, source)
        return target
      },

    /**
     * function which serializes form
     *
     * @param {object} form
     * 
     * @return {object}
     */
    serialize:function(form){
       
	if (!form || form.nodeName !== "FORM") {
		return;
	}
	var i, j,
		obj = {};
	for (i = form.elements.length - 1; i >= 0; i = i - 1) {
		if (form.elements[i].name === "") {
			continue;
		}
		switch (form.elements[i].nodeName) {
		case 'INPUT':
			switch (form.elements[i].type) {
			case 'text':
			case 'hidden':
			case 'password':
			case 'button':
			case 'reset':
			case 'submit':
				obj[form.elements[i].name] = (form.elements[i].value);
				break;
			case 'checkbox':
			case 'radio':
				if (form.elements[i].checked) {
					obj[form.elements[i].name] = (form.elements[i].value);
				}
				break;
			case 'file':
				break;
			}
			break;
		case 'TEXTAREA':
			obj[form.elements[i].name] = (form.elements[i].value);
			break;
		case 'SELECT':
			switch (form.elements[i].type) {
			case 'select-one':
				obj[form.elements[i].name] = (form.elements[i].value);
				break;
			case 'select-multiple':
				for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
					if (form.elements[i].options[j].selected) {
						obj[form.elements[i].name] = (form.elements[i].options[j].value);
					}
				}
				break;
			}
			break;
		case 'BUTTON':
			switch (form.elements[i].type) {
			case 'reset':
			case 'submit':
			case 'button':
				obj[form.elements[i].name] = (form.elements[i].value);
				break;
			}
			break;
		}
	}
	return obj;

    },
    getFormatDate:(d)=>{
        var date = new Date();
    date.setDate(date.getDate() - 1);
        d=d==undefined?date:d;
        let m=(d.getMonth()+1);
        m=m<10?'0'+m:m;
        let dt=d.getDate();
        dt=dt<10?'0'+dt:dt;
        let result= d.getFullYear()+'-'+m+'-'+dt;
        return result;
      },
      getFormatTime:(d)=>{
        d=d==undefined?new Date():d;
        let h=d.getHours();
        h=h<10?'0'+h:h;
        let m =d.getMinutes();
        m=m<10?'0'+m:m;
        let result=h+':'+m+':00';
        return result;
      },
      isDate: function(date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

};
export default Helper;